.form {
  width: 40%;
  margin: 30px 50px;
}

.active {
  text-decoration: none;
  opacity: 1;
}
/* width */

::-webkit-scrollbar {
  width: 3px;
  height: 10px;
  display: none;
}

/* button */

::-webkit-scrollbar-button {
  background: rgba(255, 255, 255, 0);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba(116, 157, 211, 0.068);
  border-radius: 20px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgba(116, 157, 211, 0.12);
}

.leaflet-popup-content-wrapper {
  background: transparent;
  box-shadow: none !important;
  border-radius: 4px !important;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-tip {
  background: #000;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

.s-circle-logo {
  /* Added this to fix the skylark logo position at i-Pad screen - size */
  @media screen and (max-width: 1180px) and (min-width: 820px) {
    top: -4% !important;
    width: 129% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .custom-divider {
    display: none;
  }
}

.leaflet-touch .leaflet-bar {
  /* padding: 12px 10px; */
  gap: 10px;
  background: linear-gradient(90deg, #38445e 0%, #1d2330 100%);
  border-radius: 8px;
  border: none;
}

.leaflet-touch .leaflet-bar a:last-child {
  margin-right: auto;
  border-radius: 4px;
  border-top: 1px solid #2ea3ff;
}

.leaflet-touch .leaflet-bar a {
  color: #2ea3ff;
  background-color: #252e43;
  border: none;
  box-shadow: -4px -4px 9px rgba(48, 62, 90, 0.55), 10px 10px 20px #12161d;
  border-radius: 4px;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #252e43 !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}

.leaflet-top {
  bottom: 0;
}

.leaflet-left {
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.right-curve {
  display: inline-block;
  width: 31px;
  height: 30px;
  position: absolute;
  top: -8px;
  right: -137px;
  z-index: 5;
  border-radius: 50%;
  box-shadow: -5px -1px 4px 0px #282f47;
  transform: rotate(-16deg);
  border-bottom-right-radius: 10px;
}

.marker {
  background: green;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #fff;
}

.marker-icon {
  font-size: 20px;
  fill: #c7ddfd;
}

.info-popup .leaflet-popup-content-wrapper {
  background: linear-gradient(0deg, #181d27, #181d27),
    linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%);
  border: 1px solid #b0b6c126;
}

.leaflet-popup-tip {
  background: transparent !important;
  box-shadow: none !important;
}

.leaflet-popup-content p {
  margin: 0;
}

.marker-label {
  width: 300px;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  color: #f5f9ff;
}

.marker-label-position {
  top: 20px;
  left: 0;
  position: relative;
}

.react-flow__attribution {
  display: none;
}

.react-flow__edge-path,
.react-flow__connection-path {
  stroke: #fff27c66 !important;
  stroke-width: 8px !important;
  border-radius: 3;
}

.react-flow__handle {
  background: #d9d9d9 !important;
}

/*style the box which holds the text of the information window*/
.gm-style .gm-style-iw {
  background-color: #181d27 !important;
  border: 1px solid rgba(176, 182, 193, 0.15);
  top: 0 !important;
  left: 0 !important;
  margin: 10px !important;
  display: block !important;
}

.gm-style .gm-style-iw-c {
  padding: 5px;
}

/*style the annoying little arrow at the bottom*/
.gm-style div div div div div div div div {
  margin: 0px 20px 5px 0px;
  padding: 0;
  top: 0;
  color: #181d27;
  font-size: 16px;
}

.gm-style-iw button {
  background: #181d27 !important;
  right: 4px !important;
  top: 4px !important;
  font-weight: 30px;
}

.gm-style-iw button span {
  background-color: #e32e01 !important;
}

.gm-style .gm-style-iw-tc::after {
  display: none;
}

.gm-control-active {
  /* border-radius: 4px; */
  background-image: linear-gradient(316deg, #252e43 0%, #171d2b 72.02%, #111722 100%) !important;
}

.gmnoprint div {
  background: #2e364883 !important;
}
