.profileimg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/** Media Quries*/

@media screen and (min-width: 780px) {
  .profileimg {
    width: 124px;
    height: 124px;
  }
}
