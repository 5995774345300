.circle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* background-size: cover; */
}

  /* @media (min-width: 1024px) and (max-width: 1439px) {
    .circle {
      height: 78%;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .circle {
      height: 58%;
    }
  } */

  /* @media (min-width: 322px) and (max-width: 760px) {
    .circle {
      height: 40%;
    }
  } */
