.circle {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: fit-content;
}
.brandlogo {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, 0%);
  /* width: 9%; */
}

@media (min-width: 2559) {
  .brandlogo {
    top: 21%;
  }
  .circle {
    top: 0%;
  }
}

@media (min-width: 1439px) {
  .brandlogo {
    top: 21%;
  }
  .circle {
    top: -2%;
  }
}

@media (min-width: 1261px) and (max-width: 1439px) {
  .circle {
    top: 0%;
  }
}

@media (min-width: 1169px) and (max-width: 1261px) {
  .circle {
    top: 0%;
  }
}

@media (min-width: 1056px) and (max-width: 1169px) {
  .circle {
    top: 0%;
  }
}

@media (min-width: 1015px) and (max-width: 1056px) {
  .circle {
    top: -5%;
  }
}

@media (min-width: 945px) and (max-width: 1015px) {
  .circle {
    top: 0%;
  }
}

@media (min-width: 700px) and (max-width: 945px) {
  .circle {
    top: 0%;
  }
}
